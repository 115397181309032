
import React, { useState, useEffect, useRef } from "react"
import { graphql, Link, useStaticQuery } from 'gatsby'
import FancyTitle from './FancyTitle';
import SingleBlogItem from './SingleBlogItem'
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const query = graphql`
  {
    allContentfulBlog {
      nodes {
        title
        id
        contentful_id
        createdAt
        excerpt
        featuredImage {
          id
          gatsbyImageData
        }
        content {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              gatsbyImageData
            }
            title
          }
        }
      }
    }
  }
`


const Blog = ({title, subtitle,limit=100,isHome}) => {
    const data = useStaticQuery(query)

    // Array of all Product articles
    const lists = data.allContentfulBlog.nodes

    // State for the list
    const [list, setList] = useState([...lists.slice(0, 4)])

    // State to trigger oad more
    const [loadMore, setLoadMore] = useState(false)

    // State of whether there is more to load
    const [hasMore, setHasMore] = useState(lists.length > 4)

    //Set a ref for the loading div
    const loadRef = useRef()

    // Handle intersection with load more div
    const handleObserver = (entities) => {
    const target = entities[0]
    if (target.isIntersecting) {
    setLoadMore(true)
    }
    }

    //Initialize the intersection observer API
    useEffect(() => {
      var options = {
        root: null,
        rootMargin: "30px",
        threshold: 1.0,
      }
      const observer = new IntersectionObserver(handleObserver, options)
      if (loadRef.current) {
        observer.observe(loadRef.current)
      }
    }, [])

    // Handle loading more articles
    useEffect(() => {
      if (hasMore) {
        const currentLength = list.length
        const isMore = currentLength < lists.length
        const nextResults = isMore
          ? lists.slice(currentLength, currentLength + 4)
          : []
        setList([...list, ...nextResults])
        setLoadMore(false)
      }
    }, [loadMore, hasMore]) //eslint-disable-line

    //Check if there is more
    useEffect(() => {
      const isMore = list.length < lists.length
      setHasMore(isMore)
    }, [list]) //eslint-disable-line

  return (
    <div id='blog' className="w-full max-w-screen-xl mx-auto box md:px-8 p-4">
      <div className='grid grid-cols-12 gap-8 md:py-10 py-3'>
        {/* <div className='md:col-span-12 col-span-12 grid'> */}
          {/* <FancyTitle title={title} subtitle={subtitle} /> */}
          {/* <div className=""> */}
              {list.slice(0,limit).map((blog , index) => (
                  // <div key={blog.id} className={`${index % 2 === 0 ? 'md:col-span-5' : 'md:col-span-7'}  ${index % 3 === 2 ? 'order-2' : ' '}  col-span-12 grid md:py-4 py-2 `}>
                    <SingleBlogItem key={blog.id} index={index} data={blog} />
                  // </div>
              ))}
          {/* </div> */}
          {!isHome &&
            <div className="mt-16 text-center">
              <div ref={loadRef}>
                {hasMore ? <p>Loading...</p> : ''}
              </div>
            </div>
          }
          {isHome &&
            <AniLink fade duration={1} to={'/blog'} className="text-2xl py-10">View all blog →</AniLink>
          }
        {/* </div> */}
      </div>
    </div>
    
  )
}

export default Blog
