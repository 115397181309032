import React from "react"
import { Link } from "gatsby"
import moment from "moment"
import slugify from "slugify"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const SingleBlogItem = ({ data, index }) => {
  console.log(data, "data")
  const { id, title, createdAt, featuredImage, excerpt } = data
  const image = getImage(featuredImage)

  const slug = slugify(title, { lower: true })
  return (
    <div key={id} className={`md:col-span-6 col-span-12 grid border border-[#e4e4e7] bg-white dark:border-[#27272A]  dark:bg-[#18181b] rounded-md overflow-hidden`}>
      <div className="h-[300px]">
        <Link to={`/${slug}/`}>
          <GatsbyImage className="block h-full" image={image} alt={title} />
        </Link>
      </div>
      <div className="p-4">
        <h2 className="md:text-xl text-lg font-semibold mt-1 mb-3">
          <Link to={`/${slug}/`}>{title}</Link>
        </h2>
        <p className="text-md line-clamp-2 mb-3">{excerpt}</p>
        <div className="flex justify-between">
          <span className="text-[12px] text-gray-800 dark:text-slate-300">
            {moment(createdAt).format("MMMM Do YYYY")}
          </span>
        </div>
      </div>
    </div>
  )
}

export default SingleBlogItem
