import React from 'react'
import Seo from '../components/Seo'
import Layout from '../components/Layout'
import Blog from '../components/Blog'
import HeroSection from '../components/HeroSection'
import loadingSpinner from "../../static/26j2Pbtoad1.json"

const blog = () => {
  return (
    <Layout>
      <Seo title="Blog"/>
      <HeroSection lottiefile={loadingSpinner} title={'Blog'} subtitle={'Essays, learning, and other miscellaneous goodies.'} />
      <Blog />
    </Layout>
  )
}

export default blog
